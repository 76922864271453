
    import { Options, Vue } from 'vue-class-component';
    import HelloWorldComponent from '@/components/HelloWorld/index.vue';
    import AccountLayout from '@/layouts/Profile/index.vue';

    @Options({
        components: {
            HelloWorldComponent,
            AccountLayout,
        },
    })
    export default class Calendar extends Vue {}

